import { object, number } from 'yup';
import i18next from '../../../../i18n/i18n';

export interface SessionConfigForm {
  session_expiration_timeout: number;
}

export const sessionConfigFormSchema = object({
  session_expiration_timeout: number()
    .typeError(i18next.t('settings.tiles.session_config.page.session_timeout_nan'))
    .min(1, i18next.t('settings.tiles.session_config.page.session_timeout_min'))
    .max(1440, i18next.t('settings.tiles.session_config.page.session_timeout_max'))
}).required();
