import { FC, FormEvent, useEffect, useState } from 'react';
import css from './settings-session-config.module.scss';
import { GetSettingsResponseDto, Permission, UpdateSettingsDto } from '../../../../types/api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useRequest from '../../../../hooks/useRequest';
import { useTranslation } from 'react-i18next';
import { SessionConfigForm, sessionConfigFormSchema } from './settings-session-config.schema';
import { getSettings, updateSettings } from '../../../../api/settings';
import { usePermission } from '../../../contexts/permission.context';
import { Header } from '../../../components/header/header.component';
import {
  Button,
  Select,
  SelectOption,
  Skeleton,
  Text,
  TextInput,
  useToaster
} from '@gravity-ui/uikit';
import { getLocalizedErrorString } from '../../../../utils/localize-error';

export const SettingsSessionConfig: FC = () => {
  const crudRequest = useRequest<Partial<GetSettingsResponseDto>>();
  const { register, formState, setValue, trigger, getValues, watch } = useForm<SessionConfigForm>({
    mode: 'onChange',
    resolver: yupResolver(sessionConfigFormSchema),
    defaultValues: sessionConfigFormSchema.getDefault()
  });
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const toaster = useToaster();

  const [isCustomSelected, setIsCustomSelected] = useState(false);
  const watchTimeout = watch('session_expiration_timeout');
  const handleUpdateTimeout = (value: string[]) => {
    setIsCustomSelected(value[0] === 'custom');
    if (value[0] === 'custom') {
      return;
    }
    setValue(`session_expiration_timeout`, Number(value[0]));
  };

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();
  };

  const handleClickSave = async () => {
    if (crudRequest.loading) return;
    const isValid = await trigger();
    if (!isValid) return;
    const values = getValues();
    try {
      const requestBody: Partial<UpdateSettingsDto> = {
        session_expiration_timeout: Number(values.session_expiration_timeout)
      };
      await crudRequest.send(updateSettings(requestBody), 1000);
      toaster.add({
        name: 'success',
        content: t('settings.tiles.session_config.page.successfully_update'),
        theme: 'success',
        autoHiding: 5000
      });
    } catch (error) {
      const localizedError = getLocalizedErrorString(error as Error);
      console.log(error);
      toaster.add({
        name: 'error',
        content: localizedError,
        theme: 'danger',
        autoHiding: 5000
      });
    }
  };

  const timeoutValues = [60, 360, 720, 1440];
  const timeoutOptions: SelectOption<number>[] = timeoutValues.map((value) => ({
    value: String(value),
    content: String(value / 60)
  }));
  timeoutOptions.push({
    content: t('settings.tiles.session_config.page.custom_timeout'),
    value: 'custom'
  });

  useEffect(() => {
    const init = async () => {
      const settings = await crudRequest.send(getSettings(['session_expiration_timeout']), 1000);
      if (settings.session_expiration_timeout) {
        setValue('session_expiration_timeout', settings.session_expiration_timeout);
      }
    };
    void init();
  }, []);

  const headerContent = (
    <Button
      view="action"
      loading={crudRequest.loading}
      disabled={!isAllowedTo(Permission.EditAdministration)}
      onClick={handleClickSave}
    >
      {t('settings.tiles.session_config.page.update_btn')}
    </Button>
  );

  const isCustomTimeout = !timeoutValues.includes(watchTimeout) || isCustomSelected;
  return (
    <div className={css.Root}>
      <Header rightContent={headerContent} />
      <div className={css.Content}>
        <div className={css.Title}>
          <Text variant="display-2">{t('settings.tiles.session_config.name')}</Text>
        </div>
        {crudRequest.loading ? (
          <Skeleton className={css.FormSkeleton} />
        ) : (
          <form className={css.FieldSet} onSubmit={handleFormSubmit}>
            <div className={css.FormItem}>
              <Text>{t('settings.tiles.session_config.page.session_timeout')}</Text>
              <Select
                options={timeoutOptions}
                value={[isCustomTimeout ? 'custom' : String(watchTimeout)]}
                onUpdate={handleUpdateTimeout}
                disabled={!isAllowedTo(Permission.EditAdministration)}
              />
            </div>
            {isCustomTimeout && (
              <div className={css.FormItem}>
                <Text>{t('settings.tiles.session_config.page.custom_timeout')}</Text>
                <TextInput
                  type="number"
                  disabled={!isAllowedTo(Permission.EditAdministration)}
                  error={formState.errors.session_expiration_timeout?.message}
                  {...register('session_expiration_timeout')}
                />
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
};
