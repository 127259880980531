import { FC } from 'react';
import css from './all-tab.module.scss';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../../../../constants';
import { SettingsPaths } from '../../../settings.const';
import { Permission } from '../../../../../../types/api';
import { useTranslation } from 'react-i18next';
import { usePermission } from '../../../../../contexts/permission.context';
import { Card, Text } from '@gravity-ui/uikit';

type ISettingItem = {
  name: string;
  path: string;
  description: string;
  requiredPermissions?: Permission[];
};

export const AllTab: FC = () => {
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();
  const navigate = useNavigate();

  const settingsItems: ISettingItem[] = [
    {
      name: t('settings.tiles.organization_information.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.ORGANIZATION}`,
      description: t('settings.tiles.organization_information.description'),
      requiredPermissions: [Permission.ReadAdministration, Permission.EditAdministration]
    },
    {
      name: t('settings.tiles.scripts.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.SCRIPT_LIST}`,
      description: t('settings.tiles.scripts.description'),
      requiredPermissions: [Permission.ReadScripts, Permission.EditScripts]
    },
    {
      name: t('settings.tiles.scripts_config.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.SCRIPTS_CONFIG}`,
      description: t('settings.tiles.scripts_config.description'),
      requiredPermissions: [Permission.ReadScripts, Permission.EditScripts]
    },
    {
      name: t('settings.tiles.policies_config.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.POLICIES_CONFIG}`,
      description: t('settings.tiles.policies_config.description'),
      requiredPermissions: [Permission.ReadScripts]
    },
    {
      name: t('settings.tiles.packages.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.PACKAGE_LIST}`,
      description: t('settings.tiles.packages.description'),
      requiredPermissions: [Permission.ReadPackages, Permission.EditPackages]
    },
    {
      name: t('settings.tiles.package_distribution_points.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.PACKAGE_POINT_LIST}`,
      description: t('settings.tiles.package_distribution_points.description'),
      requiredPermissions: [Permission.ReadPackages, Permission.EditPackages]
    },
    {
      name: t('settings.tiles.license.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.LICENSE}`,
      description: t('settings.tiles.license.description'),
      requiredPermissions: [Permission.ReadAdministration, Permission.EditAdministration]
    },
    {
      name: t('settings.tiles.upload_certificate.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.UPLOAD_CERTIFICATE}`,
      description: t('settings.tiles.upload_certificate.description'),
      requiredPermissions: [Permission.ReadAdministration, Permission.EditAdministration]
    },
    {
      name: t('settings.tiles.users.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.USER_LIST}`,
      description: t('settings.tiles.users.description'),
      requiredPermissions: [Permission.ReadAdministration, Permission.EditAdministration]
    },
    {
      name: t('settings.tiles.roles.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.ROLES_LIST}`,
      description: t('settings.tiles.roles.description'),
      requiredPermissions: [Permission.ReadAdministration, Permission.EditAdministration]
    },
    {
      name: t('settings.tiles.extension_attributes.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.EXTENSION_ATTRIBUTES_LIST}`,
      description: t('settings.tiles.extension_attributes.description'),
      requiredPermissions: [Permission.ReadDevices, Permission.EditDevices]
    },
    {
      name: t('settings.tiles.ldap.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.LDAP}`,
      description: t('settings.tiles.ldap.description'),
      requiredPermissions: [Permission.ReadAdministration, Permission.EditAdministration]
    },
    {
      name: t('settings.tiles.scep_config.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.SCEP}`,
      description: t('settings.tiles.scep_config.description'),
      requiredPermissions: [Permission.ReadAdministration, Permission.EditAdministration]
    },
    {
      name: t('settings.tiles.smart_groups_config.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.SMART_GROUPS_CONFIG}`,
      description: t('settings.tiles.smart_groups_config.description'),
      requiredPermissions: [Permission.ReadGroups, Permission.EditGroups]
    },
    {
      name: t('settings.tiles.devices_config.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.DEVICES_CONFIG}`,
      description: t('settings.tiles.devices_config.description'),
      requiredPermissions: [Permission.ReadDevices, Permission.EditDevices]
    },
    {
      name: t('settings.tiles.pki.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.PKI}`,
      description: t('settings.tiles.pki.description'),
      requiredPermissions: [Permission.ReadAdministration, Permission.EditAdministration]
    },
    {
      name: t('settings.tiles.session_config.name'),
      path: `${Paths.SETTINGS}${SettingsPaths.SESSION_CONFIG}`,
      description: t('settings.tiles.session_config.description'),
      requiredPermissions: [Permission.ReadAdministration, Permission.EditAdministration]
    }
  ].filter((item) => !item.requiredPermissions || isAllowedTo(...item.requiredPermissions));

  const handleClickFactory = (path: string) => () => {
    navigate(path);
  };

  return (
    <div className={css.Grid}>
      {settingsItems.map((settingItem, i) => (
        <Card
          key={i}
          type="selection"
          size="l"
          view="outlined"
          disabled={
            settingItem.requiredPermissions
              ? !isAllowedTo(...settingItem.requiredPermissions)
              : false
          }
          onClick={handleClickFactory(`/${settingItem.path}`)}
          className={css.Card}
        >
          <Text variant="subheader-3">{settingItem.name}</Text>
          <Text variant="body-1">{settingItem.description}</Text>
        </Card>
      ))}
    </div>
  );
};
