import React, { ComponentType, ReactNode } from 'react';
import { WithClassname } from '../../../types/common';
import {
  Table as GravityUITable,
  Pagination,
  TableColumnConfig,
  TableDataItem,
  TableSortState,
  withTableSorting,
  withTableSelection,
  TableProps,
  WithTableSelectionProps,
  WithTableSortingProps
} from '@gravity-ui/uikit';
import css from './table.module.scss';
import cn from 'classnames';

export type SortOrder = 'asc' | 'desc';

export interface Column<T> {
  id: string;
  className?: string;
  name?: string | (() => React.ReactNode);
  selector: (row: T, index: number) => ReactNode | string | undefined;
  sortable?: boolean | ((a: T, b: T) => number);
  width?: number | string;
  align?: 'start' | 'end' | 'center' | 'left' | 'right';
}

export interface SortState {
  column: string;
  order: SortOrder;
}

export interface PaginationState {
  page: number;
  rowsPerPage: number;
}

interface IProps<T> {
  columns: Column<T>[];
  data: T[];
  onChangePagination?: (state: PaginationState) => void;
  onChangeSort?: (state?: SortState) => void;
  onRowClick?: (row: T, index: number, e: React.MouseEvent<HTMLTableRowElement>) => void;
  pagination?: boolean;
  paginationRowsPerPageOptions?: number[];
  paginationState?: PaginationState;
  paginationTotalRows?: number;
  sortState?: SortState;
  selectable?: boolean;
  getRowId?: (row: T, index: number) => string;
  selectedRows?: string[];
  onSelectedRowsChange?: (rows: string[]) => void;
  leftContent?: ReactNode;
  emptyMessage?: string;
}

export function Table<T extends TableDataItem>(props: IProps<T> & WithClassname): JSX.Element {
  const {
    columns,
    data,
    onChangePagination,
    onChangeSort,
    onRowClick,
    pagination,
    paginationState = { page: 1, rowsPerPage: 10 },
    paginationRowsPerPageOptions = [10, 25, 50, 100],
    paginationTotalRows,
    sortState,
    selectable,
    getRowId,
    selectedRows = [],
    onSelectedRowsChange = () => {},
    leftContent,
    emptyMessage,
    className
  } = props;

  const TableWithSorting = withTableSorting<T>(GravityUITable);
  const TableWithSelection: ComponentType<
    TableProps<T> & WithTableSortingProps & WithTableSelectionProps<T>
  > = withTableSelection<T>(TableWithSorting);

  const firstSortableColumn = columns.findIndex((column) => !!column.sortable);
  const columnConfigs: TableColumnConfig<T>[] = columns.map((column, i) => {
    return {
      id: column.id,
      className: column.className,
      name: column.name,
      template: column.selector,
      align: column.align || 'start',
      width: column.width,
      meta: {
        sort: column.sortable,
        defaultSortOrder: i === firstSortableColumn && !sortState ? 'desc' : undefined
      }
    };
  });

  const handleSortStateChange = (sortState_: TableSortState) => onChangeSort?.(sortState_?.[0]);

  const handleUpdatePagination = (pageNumber: number, pageSize: number) =>
    onChangePagination?.({ page: pageNumber, rowsPerPage: pageSize });

  const handleGetRowDescriptor = (item: T, index: number) => {
    if (getRowId) {
      return {
        id: getRowId(item, index)
      };
    }
    return undefined;
  };

  return (
    <>
      <div className={`${css.Header} ${pagination || leftContent ? '' : css.Hidden}`}>
        {leftContent}
        {pagination && (
          <Pagination
            showInput
            showPages
            compact={false}
            page={paginationState.page}
            pageSize={paginationState.rowsPerPage}
            pageSizeOptions={paginationRowsPerPageOptions}
            total={paginationTotalRows}
            onUpdate={handleUpdatePagination}
          />
        )}
      </div>
      {selectable ? (
        <TableWithSelection
          className={cn(className, css.Table)}
          columns={columnConfigs}
          data={data}
          onRowClick={onRowClick}
          onSortStateChange={handleSortStateChange}
          sortState={sortState && [sortState]}
          getRowDescriptor={handleGetRowDescriptor}
          selectedIds={selectedRows}
          onSelectionChange={onSelectedRowsChange}
          emptyMessage={emptyMessage}
        />
      ) : (
        <TableWithSorting
          className={cn(className, css.Table)}
          columns={columnConfigs}
          data={data}
          onRowClick={onRowClick}
          onSortStateChange={handleSortStateChange}
          sortState={sortState && [sortState]}
          getRowDescriptor={handleGetRowDescriptor}
          emptyMessage={emptyMessage}
        />
      )}
    </>
  );
}
