import { FC, FormEvent, useState } from 'react';
import css from './smart-group-device-users-form.module.scss';
import { CriteriaTab } from './components/criteria-tab/criteria-tab.component';
import { useTranslation } from 'react-i18next';
import { usePermission } from '../../../../contexts/permission.context';
import { Permission } from '../../../../../types/api';
import { Tabs, TabsItemProps } from '@gravity-ui/uikit';
import { DeviceUsersTab } from '../device-users-tab/device-users-tab.component';

export enum SmartGroupDeviceUsersPageTabs {
  USERS_LIST = 'Users List',
  CRITERIA = 'Group Information'
}

interface IProps {
  mode: 'view' | 'create' | 'edit';
}

export const SmartGroupDevicesForm: FC<IProps> = (props: IProps) => {
  const { mode } = props;

  const [activeTab, setActiveTab] = useState<string>(
    mode === 'view'
      ? SmartGroupDeviceUsersPageTabs.USERS_LIST
      : SmartGroupDeviceUsersPageTabs.CRITERIA
  );
  const { t } = useTranslation();
  const { isAllowedTo } = usePermission();

  const handleChangeTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const tabs: TabsItemProps[] = [
    {
      id: SmartGroupDeviceUsersPageTabs.USERS_LIST,
      title: t('smart_groups.page.users_list_tab.name')
    },
    {
      id: SmartGroupDeviceUsersPageTabs.CRITERIA,
      title: t('smart_groups.page.criteria_tab.name')
    }
  ];
  const tabsPrepared = tabs.filter((i) => {
    if (i.id === SmartGroupDeviceUsersPageTabs.USERS_LIST && mode === 'create') {
      return false;
    }
    return true;
  });

  return (
    <form className={css.Form} onSubmit={handleFormSubmit}>
      <fieldset className={css.Fieldset} disabled={!isAllowedTo(Permission.EditGroups)}>
        <Tabs
          className={css.NavBar}
          items={tabsPrepared}
          activeTab={activeTab}
          onSelectTab={handleChangeTab}
        />
        {activeTab === SmartGroupDeviceUsersPageTabs.USERS_LIST && <DeviceUsersTab />}
        {activeTab == SmartGroupDeviceUsersPageTabs.CRITERIA && <CriteriaTab mode={mode} />}
      </fieldset>
    </form>
  );
};
