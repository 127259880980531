import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { SettingsOrganization } from './settings-organization/settings-organization.component';
import { SettingsSections } from './settings-sections/settings-sections';
import { SettingsPaths } from './settings.const';
import { SettingsPackageList } from './settings-package-list/settings-package-list';
import { SettingsPackageComponent } from './settings-package/settings-package.component';
import { SettingsPackagePointList } from './settings-package-point-list/settings-package-point-list';
import { SettingsPackagePoint } from './settings-package-point/settings-package-point';
import { SettingsLicense } from './settings-license/settings-license';
import { SettingsUploadCertificate } from './settings-upload-certificate/settings-upload-certificate';
import { Script } from './settings-script/settings-script.component';
import { ScriptList } from './settings-script-list/settings-script-list.component';
import { SettingsScriptsConfig } from './settings-scripts-config/settings-scripts-config';
import { RestrictedRoute } from '../../components/common/restricted-route';
import { Permission } from '../../../types/api';
import { UserListComponent } from './settings-user-list/user-list.component';
import { UserComponent } from './settings-user/user.component';
import { RoleListComponent } from './settings-role-list/role-list.component';
import { RoleComponent } from './settings-role/role.component';
import { SettingsPoliciesConfig } from './settings-policies-config/settings-policies-config';
import { ExtensionAttributeList } from './settings-extension-attribute-list/extension-attribute-list.component';
import { ExtensionAttribute } from './settings-extension-attribute/extension-attribute.component';
import { SettingsLdap } from './settings-ldap/settings-ldap.component';
import { SettingsScep } from './settings-scep/settings-scep';
import { SettingsGroups } from './settings-groups/settings-groups';
import { SettingsDevicesConfig } from './settings-devices/settings-devices-config';
import { SettingsPki } from './settings-pki/settings-pki';
import { SettingsSessionConfig } from './settings-session-config/settings-session-config';

export const Settings: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SettingsSections />} />
      <Route
        element={
          <RestrictedRoute to={[Permission.ReadAdministration, Permission.EditAdministration]} />
        }
      >
        <Route path={SettingsPaths.ORGANIZATION} element={<SettingsOrganization />} />
        <Route path={SettingsPaths.LICENSE} element={<SettingsLicense />} />
        <Route path={SettingsPaths.UPLOAD_CERTIFICATE} element={<SettingsUploadCertificate />} />
        <Route path={SettingsPaths.USER_LIST} element={<UserListComponent />} />
        <Route path={SettingsPaths.USER} element={<UserComponent />} />
        <Route path={SettingsPaths.ROLES_LIST} element={<RoleListComponent />} />
        <Route path={SettingsPaths.ROLE} element={<RoleComponent />} />
        <Route path={SettingsPaths.LDAP} element={<SettingsLdap />} />
        <Route path={`${SettingsPaths.PKI}/*`} element={<SettingsPki />} />
      </Route>
      <Route element={<RestrictedRoute to={[Permission.ReadPolicies, Permission.EditPolicies]} />}>
        <Route path={SettingsPaths.POLICIES_CONFIG} element={<SettingsPoliciesConfig />} />
      </Route>
      <Route element={<RestrictedRoute to={[Permission.ReadPackages, Permission.EditPackages]} />}>
        <Route path={SettingsPaths.PACKAGE_LIST} element={<SettingsPackageList />} />
        <Route path={SettingsPaths.PACKAGE} element={<SettingsPackageComponent />} />
        <Route path={SettingsPaths.PACKAGE_POINT_LIST} element={<SettingsPackagePointList />} />
        <Route path={SettingsPaths.PACKAGE_POINT} element={<SettingsPackagePoint />} />
      </Route>
      <Route element={<RestrictedRoute to={[Permission.ReadScripts, Permission.EditScripts]} />}>
        <Route path={SettingsPaths.SCRIPT} element={<Script />} />
        <Route path={SettingsPaths.SCRIPT_LIST} element={<ScriptList />} />
        <Route path={SettingsPaths.SCRIPTS_CONFIG} element={<SettingsScriptsConfig />} />
      </Route>
      <Route element={<RestrictedRoute to={[Permission.ReadDevices, Permission.EditDevices]} />}>
        <Route
          path={SettingsPaths.EXTENSION_ATTRIBUTES_LIST}
          element={<ExtensionAttributeList />}
        />
        <Route path={SettingsPaths.EXTENSION_ATTRIBUTE} element={<ExtensionAttribute />} />
      </Route>
      <Route
        element={
          <RestrictedRoute to={[Permission.ReadAdministration, Permission.EditAdministration]} />
        }
      >
        <Route path={SettingsPaths.LDAP} element={<SettingsLdap />} />
        <Route path={SettingsPaths.SCEP} element={<SettingsScep />} />
        <Route path={SettingsPaths.SMART_GROUPS_CONFIG} element={<SettingsGroups />} />
        <Route path={SettingsPaths.DEVICES_CONFIG} element={<SettingsDevicesConfig />} />
        <Route path={SettingsPaths.SESSION_CONFIG} element={<SettingsSessionConfig />} />
      </Route>
      <Route path="*" element={<Navigate to="/settings" replace />} />
    </Routes>
  );
};
