import { useLocation } from 'react-router-dom';
import { DeviceSections, Paths } from '../../constants';

export enum PanelType {
  COMPUTERS = 'computers',
  MOBILE = 'mobile',
  USERS = 'device-users'
}

export enum ComputersSubPanelType {
  INVENTORY = 'inventory',
  POLICIES = 'policies',
  PROFILES = 'configuration-profiles',
  SMART_GROUPS = 'smart-groups',
  STATIC_GROUPS = 'static-groups',
  ENROLL = 'enroll'
}

export enum MobilesSubPanelType {
  INVENTORY = 'inventory',
  PROFILES = 'configuration-profiles',
  DEVICE_APPLICATIONS = 'device-applications',
  SMART_GROUPS = 'smart-groups',
  STATIC_GROUPS = 'static-groups',
  ENROLL = 'enroll'
}

export enum UsersSubPanelType {
  LIST = 'list',
  SMART_GROUPS = 'smart-groups',
  STATIC_GROUPS = 'static-groups'
}

export enum CommonSubPanelType {
  SETTINGS = 'settings',
  EXIT = 'exit'
}

export type SubPanelType =
  | ComputersSubPanelType
  | MobilesSubPanelType
  | UsersSubPanelType
  | CommonSubPanelType;

export type PanelTypes = {
  panelType?: PanelType;
  subPanelType?: SubPanelType;
};

export function useSubPanelType(): PanelTypes {
  const location = useLocation();
  const sections = location.pathname.split('/');
  let panelType: PanelType = PanelType.COMPUTERS;
  let subPanelType:
    | ComputersSubPanelType
    | MobilesSubPanelType
    | UsersSubPanelType
    | CommonSubPanelType
    | undefined = undefined;

  switch (sections[1]) {
    case DeviceSections.COMPUTERS.replace('/', ''):
      panelType = PanelType.COMPUTERS;
      subPanelType = sections[2] as ComputersSubPanelType;
      break;
    case DeviceSections.MOBILE.replace('/', ''):
      panelType = PanelType.MOBILE;
      subPanelType = sections[2] as MobilesSubPanelType;
      break;
    case Paths.DEVICE_USER_LIST:
      panelType = PanelType.USERS;
      subPanelType = sections[2] ? (sections[2] as UsersSubPanelType) : UsersSubPanelType.LIST;
      break;
    case Paths.SETTINGS:
      subPanelType = CommonSubPanelType.SETTINGS;
      break;
  }

  return { panelType, subPanelType };
}
