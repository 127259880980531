import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/auth.context';
import { useTranslation } from 'react-i18next';
import { Dialog, Text, useToaster } from '@gravity-ui/uikit';
import { MINUTE } from '../../../utils/time.utils';

interface IProps {
  redirectTo?: string;
  children?: ReactNode;
}

export const PrivateRoute: FC<IProps> = (props: IProps) => {
  const { children, redirectTo = '/' } = props;
  const { authService } = useAuthContext();
  const [isAuthorized, setIsAuthorized] = useState<boolean>();
  const [accessToken, setAccessToken] = useState<string | null>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalClosed, setIsModalClosed] = useState(false);
  const [expiringSeconds, setExpiringSeconds] = useState<number>();
  const tickInterval = useRef<NodeJS.Timer>();
  const { t } = useTranslation();
  const toaster = useToaster();

  const handleModalClose = () => {
    setIsModalClosed(true);
    setIsModalOpen(false);
  };

  const handleRefreshToken = async () => {
    setIsModalOpen(false);
    try {
      const resp = await authService.refreshToken();
      setIsAuthorized(!!resp);
      setAccessToken(authService.accessToken);
    } catch (ex) {
      setIsAuthorized(false);
    }
  };

  const handleSignOut = () => {
    setIsAuthorized(false);
    toaster.add({
      name: 'session-expired',
      content: t('sign_in.session_expired'),
      theme: 'danger',
      autoHiding: false
    });
    authService.signOutLocalState();
  };

  useEffect(() => {
    authService.signInLocalState();
    setIsAuthorized(authService.isTokenValid());
    setAccessToken(authService.accessToken);
  }, []);

  useEffect(() => {
    if (!isAuthorized) {
      return;
    }

    toaster.remove('session-expired');

    const expiresIn = authService.getExpiresIn();
    const signoutDelay = expiresIn - Date.now();
    const signoutWarningThreshold = signoutDelay > 5 * MINUTE ? 60 : 30;
    const signoutWarningDelay = signoutDelay - signoutWarningThreshold * 1000;
    const updateExpiringSeconds = () => {
      const seconds = Math.floor((expiresIn - Date.now()) / 1000);
      setExpiringSeconds(seconds);
    };

    const signoutTimeout = setTimeout(handleSignOut, signoutDelay);
    const warningTimeout = isModalClosed
      ? undefined
      : setTimeout(async () => {
          tickInterval.current = setInterval(updateExpiringSeconds, 1000);
          updateExpiringSeconds();
          setIsModalOpen(true);
        }, signoutWarningDelay);
    return () => {
      clearTimeout(signoutTimeout);
      clearTimeout(warningTimeout);
      clearInterval(tickInterval.current);
    };
  }, [isAuthorized, accessToken]);

  if (isAuthorized === undefined) {
    return null;
  }

  const disableApply = !expiringSeconds || expiringSeconds < 5;
  return isAuthorized ? (
    <>
      <Dialog onClose={handleModalClose} onOutsideClick={handleModalClose} open={isModalOpen}>
        <Dialog.Header caption={t('sign_in.modal.title')} />
        <Dialog.Body>
          <Text>{t('sign_in.modal.message', { seconds: expiringSeconds })}</Text>
        </Dialog.Body>
        <Dialog.Footer
          textButtonApply={t('sign_in.modal.refresh_btn')}
          textButtonCancel={t('common.modal.cancel_btn')}
          onClickButtonApply={disableApply ? () => {} : handleRefreshToken}
          onClickButtonCancel={handleModalClose}
          propsButtonApply={{ disabled: disableApply }}
        />
      </Dialog>
      {children}
    </>
  ) : (
    <Navigate to={redirectTo} replace />
  );
};
