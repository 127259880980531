import {
  CreateStaticGroupDevicesRequestDto,
  GetDevicesFromStaticGroupRequestDto,
  GetDevicesFromStaticGroupResponseDto,
  GetStaticGroupsDevicesRequestDto,
  GetStaticGroupsDevicesResponseDto,
  StaticGroupDevicesFullDto,
  UpdateStaticGroupDevicesRequestDto
} from '../types/api';
import { UUIDString } from '../types/common';
import { createRequest } from './create-request';

export async function getStaticGroups(
  params: GetStaticGroupsDevicesRequestDto
): Promise<GetStaticGroupsDevicesResponseDto> {
  return createRequest({ url: 'groups/devices/static', params });
}

export async function getStaticGroup(id: string): Promise<StaticGroupDevicesFullDto> {
  return createRequest({ url: `groups/devices/static/${id}` });
}

export async function getDevicesFromStaticGroup(
  id: UUIDString,
  params: GetDevicesFromStaticGroupRequestDto
): Promise<GetDevicesFromStaticGroupResponseDto> {
  return createRequest({ url: `groups/devices/static/${id}/devices`, params });
}

export async function createStaticGroup(
  data: CreateStaticGroupDevicesRequestDto
): Promise<StaticGroupDevicesFullDto> {
  return createRequest({ method: 'POST', url: 'groups/devices/static', data });
}

export async function updateStaticGroup(
  data: UpdateStaticGroupDevicesRequestDto,
  id: string
): Promise<StaticGroupDevicesFullDto> {
  return createRequest({ method: 'PATCH', url: `groups/devices/static/${id}`, data });
}

export async function deleteStaticGroup(id: string): Promise<StaticGroupDevicesFullDto> {
  return createRequest({ method: 'DELETE', url: `groups/devices/static/${id}` });
}
