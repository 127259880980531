import jwtDecode from 'jwt-decode';
import { useState } from 'react';
import { createContainer } from 'react-tracked';
import {
  AuthLoginResponseDto,
  AuthRefreshResponseDto,
  GetActiveLicensesDto,
  LicenseStatus,
  RoleSimpleDto
} from '../../types/api';
import { createRequest } from '../../api/create-request';
import { t } from 'i18next';
import { useToaster } from '@gravity-ui/uikit';

interface IAuthContext {
  username: string;
  license: {
    iOS: LicenseStatus | null;
    macOS: LicenseStatus | null;
  };
  role: RoleSimpleDto | null;
}

const initialState: IAuthContext = {
  username: '',
  license: {
    iOS: null,
    macOS: null
  },
  role: null
};

const LocalStorageAccessTokenName = 'accessToken';
const LocalStorageAuthContextName = 'authContext';

const useMyState = () => useState(initialState);
const container = createContainer(useMyState);

let accessToken: string | undefined;
let refreshTokenPromise: Promise<AuthRefreshResponseDto> | undefined;

const AuthProvider = container.Provider;
const useAuthContext = (): { authState: IAuthContext; authService: typeof authService } => {
  const toaster = useToaster();
  const [state, setState] = container.useTracked();
  const updateState = (update: Partial<IAuthContext>) => {
    setState((prev) => ({ ...prev, ...update }));
  };

  const authService = {
    accessToken: () => accessToken,
    refreshTokenPromise: () => refreshTokenPromise,

    signInLocalState() {
      const storageToken = localStorage.getItem(LocalStorageAccessTokenName);
      const storageAuthContext = localStorage.getItem(LocalStorageAuthContextName);
      if (storageToken && storageAuthContext) {
        accessToken = storageToken;
        updateState(JSON.parse(storageAuthContext));
      }
    },

    signOutLocalState() {
      localStorage.removeItem(LocalStorageAccessTokenName);
      localStorage.removeItem(LocalStorageAuthContextName);
      updateState(initialState);
    },

    async signIn(username: string, password: string) {
      const result = await createRequest<AuthLoginResponseDto>({
        url: 'auth',
        method: 'POST',
        data: { username, password },
        withCredentials: true,
        public: true
      });
      accessToken = result.access_token;
      const authContext = { username: result.username, role: result.role };
      updateState(authContext);
      localStorage.setItem(LocalStorageAccessTokenName, accessToken);
      localStorage.setItem(LocalStorageAuthContextName, JSON.stringify(authContext));
      return result;
    },

    async signOut() {
      await createRequest({ url: 'auth/logout', withCredentials: true });
      accessToken = undefined;
      updateState(initialState);
    },

    async refreshToken() {
      if (refreshTokenPromise) {
        return refreshTokenPromise;
      }
      const promise: Promise<AuthRefreshResponseDto> = new Promise((resolve, reject) => {
        createRequest<AuthRefreshResponseDto>({
          url: 'auth/refresh',
          public: true,
          withCredentials: true
        })
          .then((resp: AuthRefreshResponseDto) => {
            accessToken = resp.access_token;
            const authContext = { username: resp.username, role: resp.role };
            updateState(authContext);
            localStorage.setItem(LocalStorageAccessTokenName, accessToken);
            localStorage.setItem(LocalStorageAuthContextName, JSON.stringify(authContext));
            refreshTokenPromise = undefined;
            createRequest<GetActiveLicensesDto>({ url: `licenses` })
              .then((response) => {
                if (!response) return null;
                updateState({
                  license: {
                    iOS: response.ios_license?.status ? response.ios_license.status : null,
                    macOS: response.macos_license?.status ? response.macos_license.status : null
                  }
                });
              })
              .catch(() => {});
            resolve(resp);
          })
          .catch((err: unknown) => {
            refreshTokenPromise = undefined;
            reject(err);
            return;
          });
      });
      refreshTokenPromise = promise;
      return promise;
    },

    isTokenValid(): boolean {
      if (!this.accessToken()) {
        return false;
      }
      return this.getExpiresIn() - Date.now() > 0;
    },

    getExpiresIn(): number {
      return this.accessToken()
        ? jwtDecode<{ exp: number }>(<string>this.accessToken()).exp * 1000
        : 0;
    },

    async setLicenseStatus() {
      createRequest<GetActiveLicensesDto>({ url: `licenses` })
        .then((resp) => {
          if (!resp) return null;
          updateState({
            license: {
              iOS: resp.ios_license?.status ? resp.ios_license.status : null,
              macOS: resp.macos_license?.status ? resp.macos_license.status : null
            }
          });
          if (resp.macos_license?.status === LicenseStatus.Expired) {
            toaster.add({
              name: 'macos-license-expired',
              content: t('settings.tiles.license.page.macos_license_expire_popup'),
              theme: 'danger',
              autoHiding: 5000
            });
          }
          if (resp.ios_license?.status === LicenseStatus.Expired) {
            toaster.add({
              name: 'ios-license-expired',
              content: t('settings.tiles.license.page.ios_license_expire_popup'),
              theme: 'danger',
              autoHiding: 5000
            });
          }
        })
        .catch(() => {});
    }
  };

  return { authState: state, authService };
};

export { AuthProvider, useAuthContext };
