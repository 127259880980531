import { FC, useEffect, useMemo, useState } from 'react';
import css from './criteria-tab.module.scss';
import {
  ConditionConjunctive,
  DeviceCriteriaDto,
  DeviceCriteriaResponseDto,
  DeviceCriteriaType,
  DeviceOsType,
  SmartGroupDevicesConditionStrippedDto
} from '../../../../../../../types/api';
import { SmartGroupFormValues } from '../../smart-group-devices-form.schema';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useDeviceSection, { DeviceType } from '../../../../../../contexts/device-section.context';
import { deviceCriteriaTitles } from '../../../../../../../const/criteria-titles.const';
import { SelectOption } from '@gravity-ui/uikit/build/esm/components/Select/types';
import { Button, Label, RadioButton, Select, Text, TextInput } from '@gravity-ui/uikit';
import { Column, Table } from '../../../../../../components/table/table.component';
import { DatePicker } from '@gravity-ui/date-components';
import { getCriteria, getCriterionValues } from '../../../../../../../api/device';
import useRequest from '../../../../../../../hooks/useRequest';
import { CriteriaTableSkeleton } from './components/criteria-table-skeleton/criteria-table-skeleton.component';
import noDataImage from '../../../../../../../assets/images/no-data.png';
import { AsyncSelect } from '../../../../../../components/async-select/async-select.component';
import { Ellipsis } from '@gravity-ui/icons';

interface IProps {
  mode: 'view' | 'create' | 'edit';
}

export const CriteriaTab: FC<IProps> = (props: IProps) => {
  const { mode } = props;

  const { control, register, setValue, getValues, watch } = useFormContext<SmartGroupFormValues>();
  const { t } = useTranslation();
  const { deviceType } = useDeviceSection();
  const osType = deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS;

  const [criteria, setCriteria] = useState<DeviceCriteriaDto[]>();
  const [criteriaValuesOpen, setCriteriaValuesOpen] = useState<boolean[]>([]);
  const fetchCriteriaRequest = useRequest<DeviceCriteriaResponseDto>();
  const criteriaValues: Record<string, SelectOption[]> = useMemo(() => ({}), []);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'criteria'
  });

  const conjunctiveOptions: SelectOption[] = [
    { content: t('smart_groups.page.criteria_tab.new_criteria.and_or.and'), value: 'and' },
    { content: t('smart_groups.page.criteria_tab.new_criteria.and_or.or'), value: 'or' }
  ];
  const operatorOptions: SelectOption[] = [
    { content: t('smart_groups.page.criteria_tab.new_criteria.operator.is'), value: '=' },
    { content: t('smart_groups.page.criteria_tab.new_criteria.operator.is_not'), value: '!=' },
    { content: t('smart_groups.page.criteria_tab.new_criteria.operator.like'), value: 'like' },
    {
      content: t('smart_groups.page.criteria_tab.new_criteria.operator.not_like'),
      value: 'not like'
    },
    { content: '>', value: '>' },
    { content: '>=', value: '>=' },
    { content: '<', value: '<' },
    { content: '<=', value: '<=' }
  ];
  const stringOperatorOptions: SelectOption[] = [
    { content: t('smart_groups.page.criteria_tab.new_criteria.operator.is'), value: '=' },
    { content: t('smart_groups.page.criteria_tab.new_criteria.operator.is_not'), value: '!=' },
    { content: t('smart_groups.page.criteria_tab.new_criteria.operator.like'), value: 'like' },
    {
      content: t('smart_groups.page.criteria_tab.new_criteria.operator.not_like'),
      value: 'not like'
    }
  ];
  const numberDateOperatorOptions: SelectOption[] = [
    { content: t('smart_groups.page.criteria_tab.new_criteria.operator.is'), value: '=' },
    { content: t('smart_groups.page.criteria_tab.new_criteria.operator.is_not'), value: '!=' },
    { content: '>', value: '>' },
    { content: '>=', value: '>=' },
    { content: '<', value: '<' },
    { content: '<=', value: '<=' }
  ];
  const booleanOperatorOptions: SelectOption[] = [{ content: '=', value: '=' }];
  const booleanOptions: SelectOption[] = [
    { content: 'true', value: 'true' },
    { content: 'false', value: 'false' }
  ];
  const criteriaOptions: SelectOption[] = criteria
    ? criteria.map((item) => ({
        content: deviceCriteriaTitles[item.name] || item.name,
        value: item.name
      }))
    : [];

  const handleAddCriterionClick = () => {
    append({
      conjunctive: fields.length ? ConditionConjunctive.And : null,
      criteria: criteria && criteria.length > 0 ? criteria[0].name : '',
      operator: '=',
      value: '',
      start_bracket: false,
      end_bracket: false
    });
    if (criteriaValuesOpen.length < fields.length) {
      criteriaValuesOpen.push(false);
      setCriteriaValuesOpen(criteriaValuesOpen);
    } else {
      criteriaValuesOpen[fields.length - 1] = false;
      setCriteriaValuesOpen(criteriaValuesOpen);
    }
  };

  const fetchCriteriaValue = (criterion: string) => async () => {
    if (criteriaValues[criterion]) return criteriaValues[criterion];

    const values = await getCriterionValues({
      criterion,
      os_type: deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS
    });
    criteriaValues[criterion] =
      Object.values(values)[0]?.map((item) => ({
        value: item.toString(),
        content: item.toString()
      })) || [];
    return criteriaValues[criterion];
  };

  const columns: Column<SmartGroupDevicesConditionStrippedDto>[] = [
    {
      id: 'conjunctive',
      name: t('smart_groups.page.criteria_tab.new_criteria.heading.and_or'),
      selector: (_, index) => {
        if (index == 0) {
          setValue(`criteria.${index}.conjunctive`, null);
          return <></>;
        }

        const selected = watch(`criteria.${index}.conjunctive`);
        const handleConjunctiveChange = (value: string) =>
          setValue(
            `criteria.${index}.conjunctive`,
            value as SmartGroupDevicesConditionStrippedDto['conjunctive']
          );

        if (mode === 'view') {
          return (
            <Text key={fields[index].id}>
              {conjunctiveOptions.find((item) => item.value === selected)?.content}
            </Text>
          );
        }
        return (
          <RadioButton
            key={fields[index].id}
            className={css.RadioButton}
            options={conjunctiveOptions}
            defaultValue={selected || undefined}
            onUpdate={handleConjunctiveChange}
            {...register(`criteria.${index}.conjunctive`)}
          />
        );
      },
      width: 100
    },
    {
      id: 'start_bracket',
      name: '',
      selector: (_, index) => {
        const checked = watch(`criteria.${index}.start_bracket`);
        const handleStartBracketClick = () => {
          setValue(`criteria.${index}.start_bracket`, !checked);
        };

        if (mode === 'view') {
          return <Text key={fields[index].id}>{checked && '('}</Text>;
        }
        return (
          <Button
            key={fields[index].id}
            view="outlined"
            className={checked ? undefined : css.UncheckedBracketCheckbox}
            onClick={handleStartBracketClick}
          >
            (
          </Button>
        );
      },
      width: 40
    },
    {
      id: 'criteria',
      name: t('smart_groups.page.criteria_tab.new_criteria.heading.criteria'),
      selector: (_, index) => {
        const watchedCriteria = watch(`criteria.${index}.criteria`);
        const handleChangeCriteria = (value: string[]) => {
          setValue(`criteria.${index}.criteria`, value[0]);

          const rowCriteriaType = criteria
            ? criteria.filter((a) => a.name === value[0])[0].type
            : DeviceCriteriaType.String;
          const options: SelectOption[] =
            rowCriteriaType === DeviceCriteriaType.String
              ? stringOperatorOptions
              : rowCriteriaType === DeviceCriteriaType.Number ||
                rowCriteriaType === DeviceCriteriaType.Date
              ? numberDateOperatorOptions
              : rowCriteriaType === DeviceCriteriaType.Boolean
              ? booleanOperatorOptions
              : operatorOptions;
          setValue(
            `criteria.${index}.operator`,
            options[0].value as SmartGroupDevicesConditionStrippedDto['operator']
          );
        };

        if (mode == 'view') {
          return (
            <Text key={fields[index].id}>
              {criteriaOptions.find((item) => item.value === watchedCriteria)?.content}
            </Text>
          );
        }
        return (
          <Select
            key={fields[index].id}
            options={criteriaOptions}
            defaultValue={criteria && criteria.length > 0 ? [criteria[0].name] : []}
            width="max"
            value={[watchedCriteria]}
            onUpdate={handleChangeCriteria}
            {...register(`criteria.${index}.criteria`)}
          />
        );
      },
      width: 300
    },
    {
      id: 'operator',
      name: t('smart_groups.page.criteria_tab.new_criteria.heading.operator'),
      selector: (_, index) => {
        const watchedCriteria = watch(`criteria.${index}.criteria`);
        const rowCriteriaType = criteria
          ? criteria.filter((a) => a.name === watchedCriteria)[0].type
          : DeviceCriteriaType.String;
        const options: SelectOption[] =
          rowCriteriaType === DeviceCriteriaType.String
            ? stringOperatorOptions
            : rowCriteriaType === DeviceCriteriaType.Number ||
              rowCriteriaType === DeviceCriteriaType.Date
            ? numberDateOperatorOptions
            : rowCriteriaType === DeviceCriteriaType.Boolean
            ? booleanOperatorOptions
            : operatorOptions;
        const watchedOperator = watch(`criteria.${index}.operator`);
        const handleChangeOperator = (value: string[]) =>
          setValue(
            `criteria.${index}.operator`,
            value[0] as SmartGroupDevicesConditionStrippedDto['operator']
          );

        if (mode === 'view') {
          return (
            <Label key={fields[index].id}>
              {operatorOptions.find((item) => item.value === watchedOperator)?.content}
            </Label>
          );
        }
        return (
          <Select
            key={fields[index].id}
            options={options}
            defaultValue={criteria && criteria.length > 0 ? [criteria[0].name] : []}
            width="max"
            value={[watchedOperator]}
            onUpdate={handleChangeOperator}
            {...register(`criteria.${index}.operator`)}
          />
        );
      },
      width: 150
    },
    {
      id: 'value',
      name: t('smart_groups.page.criteria_tab.new_criteria.heading.value'),
      selector: (_, index) => {
        const watchedCriteria = watch(`criteria.${index}.criteria`);
        const rowCriteriaType = criteria
          ? criteria.filter((a) => a.name === watchedCriteria)[0].type
          : DeviceCriteriaType.String;
        const watchedValue = getValues(`criteria.${index}.value`);

        if (mode === 'view') {
          return <Text key={fields[index].id}>{watchedValue}</Text>;
        }
        switch (rowCriteriaType) {
          default:
          case DeviceCriteriaType.String:
            return (
              <TextInput
                key={fields[index].id}
                type="text"
                {...register(`criteria.${index}.value`)}
              />
            );
          case DeviceCriteriaType.Boolean: {
            const handleBooleanValueChange = (value: string[]) =>
              setValue(`criteria.${index}.value`, value[0]);

            return (
              <Select
                key={fields[index].id}
                options={booleanOptions}
                defaultValue={[booleanOptions[0].value]}
                width="max"
                value={[watchedValue]}
                onUpdate={handleBooleanValueChange}
                {...register(`criteria.${index}.value`)}
              />
            );
          }
          case DeviceCriteriaType.Number:
            return (
              <TextInput
                key={fields[index].id}
                type="number"
                {...register(`criteria.${index}.value`)}
              />
            );
          case DeviceCriteriaType.Date:
            return (
              <DatePicker
                key={fields[index].id}
                format="YYYY-MM-DD HH:mm"
                {...register(`criteria.${index}.value`)}
              />
            );
        }
      }
    },
    {
      id: 'available_value',
      name: '',
      selector: (_, index) => {
        const watchedCriteria = getValues().criteria?.[index].criteria || criteriaOptions[0].value;
        const options: SelectOption[] = criteriaValues[watchedCriteria];

        if (mode === 'view') {
          return <></>;
        }
        return (
          <AsyncSelect
            key={fields[index].id}
            defaultOptions={options}
            onUpdate={(value) => setValue(`criteria.${index}.value`, value)}
            onLoad={fetchCriteriaValue(watchedCriteria)}
            icon={Ellipsis}
          />
        );
      },
      width: 40
    },
    {
      id: 'end_bracket',
      name: '',
      selector: (_, index) => {
        const checked = watch(`criteria.${index}.end_bracket`);
        const handleEndBracketClick = () => setValue(`criteria.${index}.end_bracket`, !checked);

        if (mode === 'view') {
          return <Text key={fields[index].id}>{checked && ')'}</Text>;
        }
        return (
          <Button
            key={fields[index].id}
            view="outlined"
            className={checked ? undefined : css.UncheckedBracketCheckbox}
            onClick={handleEndBracketClick}
          >
            )
          </Button>
        );
      },
      width: 40
    },
    {
      id: 'delete',
      name: '',
      selector: (_, index) => {
        const handleRemoveCriterionClick = () => {
          if (index === 0 && fields?.length > 1) {
            setValue('criteria.1.conjunctive', null);
            fields[1].conjunctive = null;
          }
          remove(index);
        };

        if (mode === 'view') {
          return <></>;
        }
        return (
          <Button
            key={fields[index].id}
            view="outlined-danger"
            selected
            onClick={handleRemoveCriterionClick}
          >
            {t('smart_groups.page.criteria_tab.new_criteria.delete_btn')}
          </Button>
        );
      },
      align: 'end',
      width: 0
    }
  ];

  useEffect(() => {
    const fetchCriteria = async () => {
      const result = await fetchCriteriaRequest.send(getCriteria(osType, { forSearch: false }));
      setCriteria(result.criteria);
    };

    void fetchCriteria();
  }, [osType]);

  return (
    <div className={css.Root}>
      {fetchCriteriaRequest.loading && <CriteriaTableSkeleton />}
      {!fetchCriteriaRequest.loading && fields.length === 0 && mode === 'view' && (
        <div className={css.NoDataContainer}>
          <img alt="no-data" src={noDataImage} />
          <Text variant="subheader-3">{t('inventory.filter.no_data')}</Text>
        </div>
      )}
      {!fetchCriteriaRequest.loading && (fields.length > 0 || mode !== 'view') && (
        <Table className={css.Table} columns={columns} data={fields} />
      )}
      {mode !== 'view' && (
        <Button view="action" width="auto" onClick={handleAddCriterionClick}>
          {t('inventory.filter.add_criterion_btn')}
        </Button>
      )}
    </div>
  );
};
