import { FC, useEffect, useState } from 'react';
import css from './device-users-tab.module.scss';
import {
  DeviceUserSimpleDto,
  DeviceUserSortField,
  DeviceUserSortFieldSimple,
  GetDeviceUsersFromSmartGroupResponseDto,
  PaginationSortOrder
} from '../../../../../types/api';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../../../const/pagination.const';
import {
  Column,
  PaginationState,
  SortState,
  Table
} from '../../../../components/table/table.component';
import { useParams } from 'react-router-dom';
import { Skeleton } from '@gravity-ui/uikit';
import useRequest from '../../../../../hooks/useRequest';
import { getDeviceUsersForSmartGroup } from '../../../../../api/smart-group-device-users';

export const DeviceUsersTab: FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const fetchRequest = useRequest<GetDeviceUsersFromSmartGroupResponseDto>();

  const [deviceUsers, setDeviceUsers] = useState<DeviceUserSimpleDto[]>([]);
  const [deviceUsersCount, setDeviceUsersCount] = useState(0);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: PaginationRowsPerPageOptions[0]
  });
  const [sortState, setSortState] = useState<SortState>({
    column: 'username',
    order: 'asc'
  });

  const getDeviceUsersInfo = async () => {
    if (!params.id) return;
    const response = await fetchRequest.send(
      getDeviceUsersForSmartGroup(params.id, {
        page: paginationState.page,
        limit: paginationState.rowsPerPage,
        sort_field: sortState?.column as DeviceUserSortFieldSimple,
        sort_order: sortState?.order as PaginationSortOrder
      })
    );
    setDeviceUsers(response.users);
    setDeviceUsersCount(response.count);
  };

  const handleChangePagination = (state: PaginationState) => {
    setPaginationState(state);
  };

  const handleChangeSort = (state?: SortState) => {
    if (state) setSortState(state);
  };

  useEffect(() => {
    void getDeviceUsersInfo();
  }, [paginationState, sortState]);

  const columns: Column<DeviceUserSimpleDto>[] = [
    {
      name: t('device_users.user_list.column_username'),
      selector: (row) => row.username,
      sortable: true,
      id: DeviceUserSortField.Username
    },
    {
      name: t('device_users.user_list.column_email'),
      selector: (row) => row.email,
      sortable: true,
      id: DeviceUserSortField.Email
    },
    {
      name: t('device_users.user_list.column_employee_name'),
      selector: (row) => row.employee_name,
      sortable: true,
      id: DeviceUserSortField.EmployeeName
    },
    {
      name: t('device_users.user_list.column_phone_number'),
      selector: (row) => row.phone_number,
      sortable: true,
      id: DeviceUserSortField.PhoneNumber
    },
    {
      name: t('device_users.user_list.column_position'),
      selector: (row) => row.position,
      sortable: true,
      id: DeviceUserSortField.Position
    }
  ];

  return (
    <div className={css.Root}>
      {!fetchRequest.data || fetchRequest.loading ? (
        <>
          <Skeleton className={css.TabSkeleton} />
          <Skeleton className={css.ContentSkeleton} />
        </>
      ) : (
        <Table
          className={css.Table}
          columns={columns}
          data={deviceUsers}
          onChangePagination={handleChangePagination}
          onChangeSort={handleChangeSort}
          pagination
          paginationState={paginationState}
          paginationTotalRows={deviceUsersCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          sortState={sortState}
        />
      )}
    </div>
  );
};
