import { FC, useEffect, useMemo, useState } from 'react';
import css from './device-table.module.scss';
import {
  DeviceOsType,
  DeviceSimpleDto,
  DevicesSortField,
  GetDevicesResponseDto,
  PaginationSortOrder,
  SearchDeviceByConditionDto
} from '../../../../../types/api';
import { StaticGroupFormMode } from '../../static-group-devices.component';
import { formatDate } from '../../../../../utils/time.utils';
import { useTranslation } from 'react-i18next';
import { PaginationRowsPerPageOptions } from '../../../../../const/pagination.const';
import { getDevices, searchDevices } from '../../../../../api/device';
import { useDebounce } from 'use-debounce';
import useDeviceSection, { DeviceType } from '../../../../contexts/device-section.context';
import {
  Column,
  PaginationState,
  SortState,
  Table
} from '../../../../components/table/table.component';
import { Button, TextInput } from '@gravity-ui/uikit';
import useRequest from '../../../../../hooks/useRequest';
import { TableSkeleton } from './components/table-skeleton/table-skeleton.componet';
import { UseFormReturn } from 'react-hook-form';
import { StaticGroupFormValues } from '../static-group-form/static-group-form.schema';
import { getDevicesFromStaticGroup } from '../../../../../api/static-groups-devices';
import { useParams } from 'react-router-dom';

interface IProps {
  mode: StaticGroupFormMode;
  form: UseFormReturn<StaticGroupFormValues>;
}

export const DeviceTable: FC<IProps> = (props: IProps) => {
  const { mode, form } = props;
  const { watch, setValue } = form;

  const { id } = useParams();
  const selectedDevices: DeviceSimpleDto[] = watch('devices');
  const selectedDeviceIds: string[] = selectedDevices.map((d) => d.id);
  const setSelectedDevices = (devices: DeviceSimpleDto[]) => setValue('devices', devices);

  const [groupDevices, setGroupDevices] = useState<DeviceSimpleDto[]>([]);
  const [groupDevicesCount, setGroupDevicesCount] = useState(0);
  const [devices, setDevices] = useState<DeviceSimpleDto[]>([]);
  const [devicesCount, setDevicesCount] = useState(0);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: PaginationRowsPerPageOptions[0]
  });
  const [sortState, setSortState] = useState<SortState>();
  const [paginationStateSearch, setPaginationStateSearch] = useState<PaginationState>({
    page: 1,
    rowsPerPage: PaginationRowsPerPageOptions[0]
  });
  const [sortStateSearch, setSortStateSearch] = useState<SortState>();
  const [filterText, setFilterText] = useState<string>('');
  const fetchRequest = useRequest<GetDevicesResponseDto>();
  const [debouncedFilterText] = useDebounce(filterText, 1000);

  const { t } = useTranslation();
  const { deviceType } = useDeviceSection();
  const osType = deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS;

  const columns: Column<DeviceSimpleDto>[] = [
    {
      name: t('static_groups.page.assignments_tab.heading.name'),
      selector: (row) =>
        row.device_information?.device_name ? row.device_information?.device_name : row.model_name,
      sortable: true,
      id: DevicesSortField.DisplayName
    },
    {
      name: t('static_groups.page.assignments_tab.heading.os'),
      selector: (row: DeviceSimpleDto) =>
        row.device_information && row.device_information.os_version,
      sortable: true,
      id: DevicesSortField.OsVersion
    },
    {
      name: t('static_groups.page.assignments_tab.heading.serial'),
      selector: (row: DeviceSimpleDto) => row.serial_number,
      sortable: true,
      id: DevicesSortField.SerialNumber
    },
    {
      name: t('static_groups.page.assignments_tab.heading.last_check_in'),
      selector: (row: DeviceSimpleDto) => formatDate(row.last_seen),
      sortable: true,
      id: DevicesSortField.LastSeen
    }
  ];

  const handleChangePagination = (state: PaginationState) => {
    setPaginationState(state);
  };

  const handleChangeSort = (state?: SortState) => {
    setSortState(state);
  };

  const handleChangePaginationSearch = (state: PaginationState) => {
    setPaginationStateSearch(state);
  };

  const handleChangeSortSearch = (state?: SortState) => {
    setSortStateSearch(state);
  };

  const handleSelectDevices = (deviceIds: string[]) => {
    const currentPageSelectedDevices = devices.filter((d) => deviceIds.includes(d.id));
    const otherPageSelectedDevices = selectedDevices.filter(
      (d) => !devices.some((device) => device.id === d.id)
    );
    setSelectedDevices([...currentPageSelectedDevices, ...otherPageSelectedDevices]);
  };

  const getRowId = (row: DeviceSimpleDto) => row.id;

  const getDevicesInfo = async () => {
    let response: GetDevicesResponseDto;
    if (debouncedFilterText) {
      const conditions: SearchDeviceByConditionDto[] = [
        {
          criteria: 'ModelName',
          operator: 'like',
          value: debouncedFilterText
        },
        {
          conjunctive: 'or',
          criteria: 'General.ComputerName',
          operator: 'like',
          value: debouncedFilterText
        },
        {
          conjunctive: 'or',
          criteria: 'OS.ProductVersion',
          operator: 'like',
          value: debouncedFilterText
        },
        {
          conjunctive: 'or',
          criteria: 'Hardware.SerialNumber',
          operator: 'like',
          value: debouncedFilterText
        }
      ];

      response = await fetchRequest.send(
        searchDevices({
          conditions,
          page: paginationStateSearch.page,
          limit: paginationStateSearch.rowsPerPage,
          sort_field: sortStateSearch?.column as DevicesSortField,
          sort_order: sortStateSearch?.order as PaginationSortOrder,
          os_type: deviceType === DeviceType.COMPUTERS ? DeviceOsType.MacOS : DeviceOsType.IOS
        }),
        1000
      );
    } else {
      response = await fetchRequest.send(
        getDevices({
          page: paginationStateSearch.page,
          limit: paginationStateSearch.rowsPerPage,
          sort_field: sortStateSearch?.column as DevicesSortField,
          sort_order: sortStateSearch?.order as PaginationSortOrder,
          os_type: osType
        }),
        1000
      );
    }

    setDevices(response.devices);
    setDevicesCount(response.count);
  };

  const getStaticGroupDevicesInfo = async () => {
    if (!id) return;
    const response = await getDevicesFromStaticGroup(id, {
      page: paginationState.page,
      limit: paginationState.rowsPerPage,
      sort_field: sortState?.column as DevicesSortField,
      sort_order: sortState?.order as PaginationSortOrder
    });
    setGroupDevices(response.devices);
    setGroupDevicesCount(response.count);
  };

  useEffect(() => {
    if (mode === 'view') return;
    void getDevicesInfo();
  }, [mode, paginationStateSearch, sortStateSearch, debouncedFilterText]);

  useEffect(() => {
    if (mode !== 'view') return;
    void getStaticGroupDevicesInfo();
  }, [devices, mode, paginationState, sortState]);

  const leftContent = useMemo(() => {
    const handleClear = () => {
      setFilterText('');
    };

    return (
      <div className={css.Filter}>
        <TextInput
          className={css.FilterInput}
          value={filterText}
          onUpdate={setFilterText}
          placeholder={t('static_groups.page.assignments_tab.filter.message.devices')}
        />
        <Button view="action" onClick={handleClear}>
          {t('static_groups.page.assignments_tab.filter.clear_btn')}
        </Button>
      </div>
    );
  }, [filterText]);

  return (
    <div className={css.Root}>
      {fetchRequest.loading ? (
        <TableSkeleton />
      ) : mode === StaticGroupFormMode.VIEW ? (
        <Table
          columns={columns}
          data={groupDevices}
          pagination
          paginationState={paginationState}
          onChangePagination={handleChangePagination}
          paginationTotalRows={groupDevicesCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          sortState={sortState}
          onChangeSort={handleChangeSort}
        />
      ) : (
        <Table
          columns={columns}
          data={devices}
          pagination
          paginationState={paginationStateSearch}
          onChangePagination={handleChangePaginationSearch}
          paginationTotalRows={devicesCount}
          paginationRowsPerPageOptions={PaginationRowsPerPageOptions}
          sortState={sortStateSearch}
          onChangeSort={handleChangeSortSearch}
          selectable
          selectedRows={selectedDeviceIds}
          onSelectedRowsChange={handleSelectDevices}
          getRowId={getRowId}
          leftContent={leftContent}
        />
      )}
    </div>
  );
};
